@font-face {
  font-family: GothamPro;
  src: url("GothamPro-Light.98435459.eot");
  src: url("GothamPro-Light.704a68e0.woff2") format("woff2"), url("GothamPro-Light.87d553fd.woff") format("woff"), url("GothamPro-Light.fd4b7631.ttf") format("truetype"), url("GothamPro-Light.08fba948.svg#GothamPro-Light") format("svg"), url("GothamPro-Light.98435459.eot#iefix") format("embedded-opentype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: GothamPro;
  src: url("GothamPro-LightItalic.b0bfcdd4.eot");
  src: url("GothamPro-LightItalic.8e538341.woff2") format("woff2"), url("GothamPro-LightItalic.2d7ff3b0.woff") format("woff"), url("GothamPro-LightItalic.4f27fc82.ttf") format("truetype"), url("GothamPro-LightItalic.c78c1d2b.svg#GothamPro-LightItalic") format("svg"), url("GothamPro-LightItalic.b0bfcdd4.eot#iefix") format("embedded-opentype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: GothamPro;
  src: url("GothamPro.db2b0145.eot");
  src: url("GothamPro.6fd5d9db.woff2") format("woff2"), url("GothamPro.300290b3.woff") format("woff"), url("GothamPro.d07f89f0.ttf") format("truetype"), url("GothamPro.efb647dd.svg#GothamPro") format("svg"), url("GothamPro.db2b0145.eot#iefix") format("embedded-opentype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: GothamPro;
  src: url("GothamPro-Italic.d93e987c.eot");
  src: url("GothamPro-Italic.c4cccd39.woff2") format("woff2"), url("GothamPro-Italic.ef12e533.woff") format("woff"), url("GothamPro-Italic.498da107.ttf") format("truetype"), url("GothamPro-Italic.71bf6a9b.svg#GothamPro-Italic") format("svg"), url("GothamPro-Italic.d93e987c.eot#iefix") format("embedded-opentype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: GothamPro;
  src: url("GothamPro-Medium.e7072360.eot");
  src: url("GothamPro-Medium.43ea2a71.woff2") format("woff2"), url("GothamPro-Medium.b281c3f3.woff") format("woff"), url("GothamPro-Medium.13c7c70d.ttf") format("truetype"), url("GothamPro-Medium.630922e6.svg#GothamPro-Medium") format("svg"), url("GothamPro-Medium.e7072360.eot#iefix") format("embedded-opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: GothamPro;
  src: url("GothamPro-MediumItalic.e23333eb.eot");
  src: url("GothamPro-MediumItalic.4b202832.woff2") format("woff2"), url("GothamPro-MediumItalic.6771cc50.woff") format("woff"), url("GothamPro-MediumItalic.5abf2989.ttf") format("truetype"), url("GothamPro-MediumItalic.0e1e6e30.svg#GothamPro-MediumItalic") format("svg"), url("GothamPro-MediumItalic.e23333eb.eot#iefix") format("embedded-opentype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: GothamPro;
  src: url("GothamPro-Bold.c4ad5598.eot");
  src: url("GothamPro-Bold.97b556db.woff2") format("woff2"), url("GothamPro-Bold.497b2c5c.woff") format("woff"), url("GothamPro-Bold.5b18a22a.ttf") format("truetype"), url("GothamPro-Bold.86c1ab5c.svg#GothamPro-Bold") format("svg"), url("GothamPro-Bold.c4ad5598.eot#iefix") format("embedded-opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: GothamPro;
  src: url("GothamPro-BoldItalic.12deeb71.eot");
  src: url("GothamPro-BoldItalic.61744daf.woff2") format("woff2"), url("GothamPro-BoldItalic.5edc4755.woff") format("woff"), url("GothamPro-BoldItalic.830204f4.ttf") format("truetype"), url("GothamPro-BoldItalic.8b2c68c6.svg#GothamPro-BoldItalic") format("svg"), url("GothamPro-BoldItalic.12deeb71.eot#iefix") format("embedded-opentype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: GothamPro;
  src: url("GothamPro-Black.84a6d90f.eot");
  src: url("GothamPro-Black.4086d399.woff2") format("woff2"), url("GothamPro-Black.d5fdd00c.woff") format("woff"), url("GothamPro-Black.83ff069c.ttf") format("truetype"), url("GothamPro-Black.63c61063.svg#GothamPro-Black") format("svg"), url("GothamPro-Black.84a6d90f.eot#iefix") format("embedded-opentype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: GothamPro;
  src: url("GothamPro-BlackItalic.2c311c3c.eot");
  src: url("GothamPro-BlackItalic.908c4bf9.woff2") format("woff2"), url("GothamPro-BlackItalic.8d874047.woff") format("woff"), url("GothamPro-BlackItalic.7e025a53.ttf") format("truetype"), url("GothamPro-BlackItalic.0ea6fc96.svg#GothamPro-BlackItalic") format("svg"), url("GothamPro-BlackItalic.2c311c3c.eot#iefix") format("embedded-opentype");
  font-weight: 800;
  font-style: italic;
}

:root, [data-bs-theme="lcghomes"] {
  --bs-primary: #f05123;
  --bs-body-color: #2b2b2b;
  --bs-font-sans-serif: "GothamPro", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --font-heading: "GothamPro", "HelveticaNeueBoldCondensed", "HelveticaNeue-Bold-Condensed", "Helvetica Neue Bold Condensed", "HelveticaNeueBold", "HelveticaNeue-Bold", "Helvetica Neue Bold", "HelveticaNeue", "Helvetica Neue", "TeXGyreHerosCnBold", "Helvetica", "Tahoma", "Geneva", "Arial Narrow", "Arial", sans-serif;
  --bs-body-font-weight: 200;
}

body {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  margin: 0;
}

.font-heading {
  font-family: var(--font-heading);
  font-weight: 600;
  font-stretch: condensed;
}

.text-primary {
  color: var(--bs-primary) !important;
}

.bg-primary {
  background-color: var(--bs-primary) !important;
}

h1 {
  text-transform: uppercase;
  color: var(--bs-primary);
  font-weight: bold;
  font-family: var(--font-heading);
  font-size: 3rem;
  font-weight: 600;
  font-stretch: condensed;
}

h2 {
  text-transform: uppercase;
  color: var(--bs-primary);
  font-weight: bold;
  font-family: var(--font-heading);
  font-weight: 600;
  font-stretch: condensed;
}

h3 {
  text-transform: uppercase;
  color: #676767;
  font-weight: bold;
  font-family: var(--font-heading);
  font-weight: 600;
  font-stretch: condensed;
}

h4 {
  text-transform: uppercase;
  color: var(--bs-primary);
  font-weight: bold;
  font-family: var(--font-heading);
  font-weight: 600;
  font-stretch: condensed;
}

h5 {
  text-transform: uppercase;
  color: #676767;
  font-weight: bold;
  font-family: var(--font-heading);
  font-weight: 600;
  font-stretch: condensed;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--bs-primary);
  --bs-btn-border-color: var(--bs-primary);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--bs-primary);
  --bs-btn-hover-border-color: var(--bs-primary);
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--bs-primary);
  --bs-btn-active-border-color: var(--bs-primary);
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--bs-primary);
  --bs-btn-disabled-border-color: var(--bs-primary);
}

.btn-lg {
  --bs-btn-padding-x: 3rem;
  --bs-btn-padding-y: 1.375rem;
  min-width: 400px;
}

.section {
  --section-padding-x: 0;
  --section-padding-y: 60px;
  padding: var(--section-padding-y) var(--section-padding-x);
}

.section img {
  max-width: 100%;
  height: auto;
}

.mw-800 {
  max-width: 800px;
}

.p-10 {
  padding: 3rem;
}

.pt-10 {
  padding-top: 3rem;
}

.bt-1 {
  border-top: 1px solid #000;
}

.be-1 {
  border-right: 1px solid #000;
}

.section-footer {
  --section-padding-x: 0;
  --section-padding-y: 0;
  border-top: 1px solid #000;
}

.section-footer a {
  color: #000;
  cursor: pointer;
  text-decoration: none;
}

.section-footer a:hover {
  color: var(--bs-primary);
}

.hero-text h1 {
  text-shadow: 2px 2px 2px #0000004d;
}

.hero .font-signpainter {
  font-size: 5rem;
  transform: rotate(-3deg);
}

.hero .btn {
  font-family: var(--font-heading);
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 1.5rem;
  font-weight: 600;
  font-stretch: condensed;
}

.carousel-indicators [data-bs-target] {
  background-color: var(--bs-primary);
}

@media (max-width: 768px) {
  .carousel-item {
    height: 500px;
  }

  .carousel-item img {
    min-width: 100%;
    max-width: none;
    height: 100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: auto !important;
  }
}

.section-image-split {
  --split-width: 50%;
  --content-padding-x: 3vw;
  --content-padding-y: 3rem;
  --section-padding-y: 0;
}

.section-image-split .content {
  padding: var(--content-padding-y) var(--content-padding-x);
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.section-image-split .image {
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.section-image-split .image img {
  object-fit: cover;
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
}

@media (min-width: 768px) {
  .section-image-split {
    --section-padding-y: 60px;
    display: flex;
  }

  .section-image-split .image, .section-image-split .content {
    width: var(--split-width);
  }

  .section-image-split-right .image {
    order: 1;
  }
}

/*# sourceMappingURL=index.572e6678.css.map */
