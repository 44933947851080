@import url('../fonts/gotham/gotham.css');

:root,
[data-bs-theme=lcghomes] { 
    --bs-primary: #F05123;
    --bs-body-color: #2b2b2b;
    --bs-font-sans-serif: "GothamPro", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --font-heading: "GothamPro", "HelveticaNeueBoldCondensed", "HelveticaNeue-Bold-Condensed", "Helvetica Neue Bold Condensed", "HelveticaNeueBold", "HelveticaNeue-Bold", "Helvetica Neue Bold", "HelveticaNeue", "Helvetica Neue", 'TeXGyreHerosCnBold', "Helvetica", "Tahoma", "Geneva", "Arial Narrow", "Arial", sans-serif;
    --bs-body-font-weight: 200;
}

body {
    margin: 0;
    font-family: var(--bs-body-font-family);
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
    line-height: var(--bs-body-line-height);
    color: var(--bs-body-color);
    text-align: var(--bs-body-text-align);
    background-color: var(--bs-body-bg);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.font-heading {
    font-family: var(--font-heading); 
    font-weight:600; 
    font-stretch:condensed;
}
.text-primary {
    color: var(--bs-primary) !important;
}
.bg-primary {
    background-color: var(--bs-primary) !important;
}

h1 {
    font-weight: bold;
    text-transform: uppercase;
    color: var(--bs-primary);
    font-family: var(--font-heading); 
    font-weight:600; 
    font-stretch:condensed;
    font-size: 3rem;
}
h2 {
    font-weight: bold;
    text-transform: uppercase;
    color: var(--bs-primary);
    font-family: var(--font-heading); 
    font-weight:600; 
    font-stretch:condensed;
}
h3 {
    font-weight: bold;
    text-transform: uppercase;
    color: #676767;
    font-family: var(--font-heading); 
    font-weight:600; 
    font-stretch:condensed;
}
h4 {
    font-weight: bold;
    text-transform: uppercase;
    color: var(--bs-primary);
    font-family: var(--font-heading); 
    font-weight:600; 
    font-stretch:condensed;
}
h5 {
    font-weight: bold;
    text-transform: uppercase;
    color: #676767;
    font-family: var(--font-heading); 
    font-weight:600; 
    font-stretch:condensed;
}

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: var(--bs-primary);
    --bs-btn-border-color: var(--bs-primary);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--bs-primary);
    --bs-btn-hover-border-color: var(--bs-primary);
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--bs-primary);
    --bs-btn-active-border-color: var(--bs-primary);
    --bs-btn-active-shadow: inset 0 3px 5px #00000020;
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: var(--bs-primary);
    --bs-btn-disabled-border-color: var(--bs-primary);
}

.btn-lg {
    --bs-btn-padding-x: 3rem;
    --bs-btn-padding-y: 1.375rem;
    min-width: 400px;
}

.section {
    --section-padding-x: 0;
    --section-padding-y: 60px;
    padding: var(--section-padding-y) var(--section-padding-x);
}   
.section img {
    max-width: 100%;
    height: auto;
}

.mw-800 {
    max-width: 800px;
}
.p-10 {
    padding: 3rem;
}
.pt-10 {
    padding-top: 3rem;
}
.bt-1 {
    border-top: 1px solid #000;
}
.be-1 {
    border-right: 1px solid #000;
}
.section-footer {
    --section-padding-x: 0;
    --section-padding-y: 0;
    border-top: 1px solid #000;
}
.section-footer a {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}
.section-footer a:hover {
    color: var(--bs-primary)
}

.hero-text h1 {
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.30);
}
.hero .font-signpainter {
    transform: rotate(-3deg);
    font-size: 5rem;
}
.hero .btn {
    font-family: var(--font-heading); 
    font-weight:600; 
    font-stretch:condensed;
    font-size: 1.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

.carousel-indicators [data-bs-target] {
    background-color: var(--bs-primary);
}

@media (max-width: 768px ) {
    .carousel-item { 
        height: 500px;
    } 
    .carousel-item img { 
        height: 100%;
        width: auto !important;
        max-width: none;
        min-width: 100%;
        left: 50%;
        position: relative;
        transform:  translateX(-50%);
    } 
}


.section-image-split {
    --split-width: 50%;
    --content-padding-x: 3vw;
    --content-padding-y: 3rem;
    --section-padding-y: 0;
}
.section-image-split .content {
    padding: var(--content-padding-y) var(--content-padding-x);
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.section-image-split .image {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden
}
.section-image-split .image img {
    flex-shrink: 0;
    object-fit: cover;
    min-width: 100%;
    min-height: 100%;
}
@media (min-width: 768px ) {
    .section-image-split {
        display: flex;
        --section-padding-y: 60px;
    }
    .section-image-split .image,
    .section-image-split .content {
        width: var(--split-width);
    }

    .section-image-split-right .image {
        order: 1;
    }
}